
#menu_forcefullwidth { z-index: 5000; position:fixed !important;top:0px;left:0px;width:100%;height:80px !important;overflow:hidden;}
#menu_forcefullwidth.opened { height:100% !important}
#menu_wrapper { height:100% !important; }
.tp-caption.tp-linkmod:hover { text-decoration: underline !important; }	#welcome_wrapper .tp-loader.spinner3 div { background-color: #FFFFFF !important; } .tp-caption.tp-linkmod:hover { text-decoration: underline !important; }.new-bullet-bar.tparrows {
  cursor:pointer;
  background:rgba(255,255,255,1);
  min-width:80px;
  min-height:80px;
  position:absolute;
  display:block;
  z-index:100;
  border-radius:0px;   
}

.new-bullet-bar.tparrows:before {
  font-family: "revicons";
  font-size:20px;
  color:#2d3032;
  display:block;
  line-height: 80px;
  text-align: center;    
  z-index:2;
  position:relative;
}
.new-bullet-bar.tparrows.tp-leftarrow:before {
  content: "\e824";
}
.new-bullet-bar.tparrows.tp-rightarrow:before {
  content: "\e825";
}

.new-bullet-bar .tp-title-wrap { 
  position:absolute;
  z-index:1;
  display:inline-block;
  background:#000;
  background:rgba(0,0,0,0.5);
  min-height:80px;
  line-height:80px;
  top:0px;
  margin-left:0px;
  border-radius:0px;
  overflow:hidden; 
  transition: opacity 0.6s;
  -webkit-transition:opacity 0.6s;
  -moz-transition:opacity 0.6s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);  
  visibility:hidden;
  opacity:0;
}

.new-bullet-bar.tparrows:hover .tp-title-wrap{
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  opacity:1;
  visibility:visible;
}

.new-bullet-bar.tparrows:hover:before {
  color:#fff;
}
        
 .new-bullet-bar.tp-rightarrow .tp-title-wrap { 
   right:0px;
   -webkit-transform-origin:100% 50%;
   transform-origin:100% 50%;
  border-radius:0px;
width: 160px;
height: 100px; 
margin-top:-10px;
margin-right:-40px;
-webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.35);
-moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.35);
box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.35);
 }


.new-bullet-bar.tp-leftarrow .tp-title-wrap { 
     left:0px;
   -webkit-transform-origin:100% 50%;
   transform-origin:100% 50%;
  border-radius:0px;
width: 160px;
height: 100px;
margin-top:-10px;
margin-left:-40px;
-webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.35);
-moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.35);
box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.35);
}

.new-bullet-bar .tp-arr-imgholder {
  width:100%;
  height:100%;
  position:absolute;
  top:0px;
  left:0px;
  background-position:center center;
  background-size:cover;
    }
    
 .new-bullet-bar .tp-arr-img-over {
   width:100%;
  height:100%;
  position:absolute;
  top:0px;
  left:0px;
   background:#000;
   background:rgba(0,0,0,0.25);
        }


.hermes .tp-bullet {
    overflow:hidden;
    border-radius:50%;
    width:16px;
    height:16px;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 2px rgb(255, 255, 255);
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    position:absolute;
}

.hermes .tp-bullet:hover {
	  background-color: rgba(0,0,0,0.21);
}
.hermes .tp-bullet:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  height: 0;
  left: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 1px rgb(255, 255, 255);
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.hermes .tp-bullet.selected:after {
  height:100%;
}

	#whatshot_wrapper .tp-loader.spinner3 div { background-color: #FFFFFF !important; } .tp-caption.tp-linkmod:hover { text-decoration: underline !important; }	#featured_wrapper .tp-loader.spinner3 div { background-color: #FFFFFF !important; } .tp-va { vertical-align: bottom; }

.tp-btnshadow {
	-webkit-box-shadow: 0px 10px 10px 0px rgba(45,48,50,0.15) !important;
	-moz-box-shadow: 0px 10px 10px 0px rgba(45,48,50,0.15) !important;
	box-shadow: 0px 10px 10px 0px rgba(45,48,50,0.15) !important;
    }

.tp-caption.tp-linkmod:hover { text-decoration: underline !important; }	#spotlight_wrapper .tp-loader.spinner3 div { background-color: #FFFFFF !important; } .tp-caption.tp-linkmod:hover { text-decoration: underline !important; }#spotlight .uranus.tparrows {
  width:50px;
  height:50px;
  background:rgba(255,255,255,0);
 }
 #spotlight .uranus.tparrows:before {
 width:50px;
 height:50px;
 line-height:50px;
 font-size:40px;
 transition:all 0.3s;
-webkit-transition:all 0.3s;
 }
 
  #spotlight .uranus.tparrows:hover:before {
    opacity:0.75;
  }


.hermes .tp-bullet {
    overflow:hidden;
    border-radius:50%;
    width:16px;
    height:16px;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 2px rgb(255, 255, 255);
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    position:absolute;
}

.hermes .tp-bullet:hover {
	  background-color: rgba(0,0,0,0.21);
}
.hermes .tp-bullet:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  height: 0;
  left: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 1px rgb(255, 255, 255);
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.hermes .tp-bullet.selected:after {
  height:100%;
}

	#archives_wrapper .tp-loader.spinner3 div { background-color: #FFFFFF !important; } .tiny_bullet_slider .tp-bullet:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 25px;
    top:-12px;
    left:0px;
    background: transparent;
  }
.tp-caption.tp-linkmod:hover { text-decoration: underline !important; }
.new-bullet-bar.tp-bullets:before {
	content:" ";
	position:absolute;
	width:100%;
	height:100%;
	background:transparent;
	padding:10px;
	margin-left:-10px;margin-top:-10px;
	box-sizing:content-box;
}
.new-bullet-bar .tp-bullet {
	width:60px;
	height:3px;
	position:absolute;
	background:#aaa;
    background:rgba(45,48,50,0.2);
	cursor: pointer;
	box-sizing:content-box;
}
.new-bullet-bar .tp-bullet:hover,
.new-bullet-bar .tp-bullet.selected {
	background:rgba(45,48,50,0.65);
}


.rs-boxshadow{
-webkit-box-shadow: 1px 1px 0px 0px rgba(87,89,91,1),2px 2px 0px 0px rgba(87,89,91,1),3px 3px 0px 0px rgba(87,89,91,1),4px 4px 0px 0px rgba(87,89,91,1),5px 5px 0px 0px rgba(87,89,91,1),6px 6px 0px 0px rgba(87,89,91,1),7px 7px 0px 0px rgba(87,89,91,1);
-moz-box-shadow: 1px 1px 0px 0px rgba(87,89,91,1),2px 2px 0px 0px rgba(87,89,91,1),3px 3px 0px 0px rgba(87,89,91,1),4px 4px 0px 0px rgba(87,89,91,1),5px 5px 0px 0px rgba(87,89,91,1),6px 6px 0px 0px rgba(87,89,91,1),7px 7px 0px 0px rgba(87,89,91,1);
box-shadow: 1px 1px 0px 0px rgba(87,89,91,1),2px 2px 0px 0px rgba(87,89,91,1),3px 3px 0px 0px rgba(87,89,91,1),4px 4px 0px 0px rgba(87,89,91,1),5px 5px 0px 0px rgba(87,89,91,1),6px 6px 0px 0px rgba(87,89,91,1),7px 7px 0px 0px rgba(87,89,91,1);
    }
    
    .rs-boxshadow2{
-webkit-box-shadow: -1px 1px 0px 0px rgba(40,43,45,1),-2px 2px 0px 0px rgba(40,43,45,1),-3px 3px 0px 0px rgba(40,43,45,1),-4px 4px 0px 0px rgba(40,43,45,1),-5px 5px 0px 0px rgba(40,43,45,1),-6px 6px 0px 0px rgba(40,43,45,1),-7px 7px 0px 0px rgba(40,43,45,1);
-moz-box-shadow: -1px 1px 0px 0px rgba(40,43,45,1),-2px 2px 0px 0px rgba(40,43,45,1),-3px 3px 0px 0px rgba(40,43,45,1),-4px 4px 0px 0px rgba(40,43,45,1),-5px 5px 0px 0px rgba(40,43,45,1),-6px 6px 0px 0px rgba(40,43,45,1),-7px 7px 0px 0px rgba(40,43,45,1);
box-shadow: -1px 1px 0px 0px rgba(40,43,45,1),-2px 2px 0px 0px rgba(40,43,45,1),-3px 3px 0px 0px rgba(40,43,45,1),-4px 4px 0px 0px rgba(40,43,45,1),-5px 5px 0px 0px rgba(40,43,45,1),-6px 6px 0px 0px rgba(40,43,45,1),-7px 7px 0px 0px rgba(40,43,45,1);
    }.nc-shadow {-webkit-box-shadow: 0px 5px 75px 0px rgba(45,48,50,0.35);
-moz-box-shadow: 0px 5px 75px 0px rgba(45,48,50,0.35);
box-shadow: 0px 5px 75px 0px rgba(45,48,50,0.35); }