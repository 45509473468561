.rsaddon-revealer-spinner {
	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	
}

.rsaddon-revealer-spinner-1 {

	width: 40px;
    height: 40px;
	
}

/* ************* */
/* PRELOADER ONE */
/* ************* */
.rsaddon-revealer-1 {
	
	width: 100%;
	height: 100%;
	display:inline-block;
	-webkit-animation:rsaddon-revealer-1 1.4s linear infinite;
	animation:rsaddon-revealer-1 1.4s linear infinite;
   
}

.rsaddon-revealer-1 span {
	
	position:absolute;
	vertical-align:top;
	border-radius:100%;
	display:inline-block;
	width:8px;
	height:8px;
	margin-left:16px;
	transform-origin:center 20px;
	-webkit-transform-origin:center 20px;
   
}

.rsaddon-revealer-1 span:nth-child(2) {transform: rotate(36deg); -webkit-transform: rotate(36deg); opacity:0.1}
.rsaddon-revealer-1 span:nth-child(3) {transform: rotate(72deg); -webkit-transform: rotate(72deg); opacity:0.2}
.rsaddon-revealer-1 span:nth-child(4) {transform: rotate(108deg); -webkit-transform: rotate(108deg); opacity:0.3}
.rsaddon-revealer-1 span:nth-child(5) {transform: rotate(144deg); -webkit-transform: rotate(144deg); opacity:0.4}
.rsaddon-revealer-1 span:nth-child(6) {transform: rotate(180deg); -webkit-transform: rotate(180deg); opacity:0.5}
.rsaddon-revealer-1 span:nth-child(7) {transform: rotate(216deg); -webkit-transform: rotate(216deg); opacity:0.6}
.rsaddon-revealer-1 span:nth-child(8) {transform: rotate(252deg); -webkit-transform: rotate(252deg); opacity:0.7}
.rsaddon-revealer-1 span:nth-child(9) {transform: rotate(288deg); -webkit-transform: rotate(288deg); opacity:0.8}
.rsaddon-revealer-1 span:nth-child(10) {transform: rotate(324deg); -webkit-transform: rotate(324deg); opacity:0.9}

@keyframes rsaddon-revealer-1 {
	
   from {transform: rotate(0deg)}
   to {transform: rotate(360deg)}
   
}
@-webkit-keyframes rsaddon-revealer-1 {
	
   from {-webkit-transform: rotate(0deg)}
   to {-webkit-transform: rotate(360deg)}
   
}

/* ************* */
/* PRELOADER TWO */
/* ************* */
.rsaddon-revealer-spinner-2 {
	
	width: 35px;
	height: 35px;
	
}

.rsaddon-revealer-2 {
	
	width: 100%;
	height: 100%;
	display: inline-block;
	padding: 0px;
	border-radius: 100%;
	border: 2px solid;
	-webkit-animation: rsaddon-revealer-2 0.8s linear infinite;
	animation: rsaddon-revealer-2 0.8s linear infinite;
   
}

@keyframes rsaddon-revealer-2 {
	
	from {transform: rotate(0deg)}
	to {transform: rotate(360deg)}
   
}
@-webkit-keyframesrsaddon-revealer-2 {
	
	from {-webkit-transform: rotate(0deg)}
	to {-webkit-transform: rotate(360deg)}
   
}

/* *************** */
/* PRELOADER THREE */
/* *************** */
.rsaddon-revealer-spinner-8 {
	
	width: 40px;
	height: 40px;
	
}

.rsaddon-revealer-8 {
	
	width: 100%;
	height: 100%;
	
}

.rsaddon-revealer-8 span {
	
	display: block;
	width: 40%;
	height: 40%;
	position: absolute;
	border-radius: 50%;
	-webkit-animation: rsaddon-revealer-8 2s ease infinite;
	animation: rsaddon-revealer-8 2s ease infinite;
	
}

.rsaddon-revealer-8 span:nth-child(1) {animation-delay: -1.5s; -webkit-animation-delay: -1.5s}
.rsaddon-revealer-8 span:nth-child(2) {animation-delay: -1s; -webkit-animation-delay: -1s}
.rsaddon-revealer-8 span:nth-child(3) {animation-delay: -0.5s; -webkit-animation-delay: -0.5s}

@keyframes rsaddon-revealer-8 {
	
	0%,100%{transform: translate(0)}
	25%{transform: translate(160%)}
	50%{transform: translate(160%, 160%)}
	75%{transform: translate(0, 160%)}
   
}

@-webkit-keyframes rsaddon-revealer-8 {
	
	0%,100%{-webkit-transform: translate(0)}
	25%{-webkit-transform: translate(160%)}
	50%{-webkit-transform: translate(160%, 160%)}
	75%{-webkit-transform: translate(0, 160%)}
   
}

/* *************** */
/* PRELOADER FOUR  */
/* *************** */
.rsaddon-revealer-spinner-3 {
	
	width: 50px;
	height: 50px;
	
}


.rsaddon-revealer-3 {
	
	width: 100%;
	height: 100%;
	display: inline-block;
	padding: 0px;
	text-align: left;
   
}
.rsaddon-revealer-3 span {
	
	position: absolute;
	display: inline-block;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	-webkit-animation: rsaddon-revealer-3 1.6s linear infinite;
	animation: rsaddon-revealer-3 1.6s linear infinite;
   
}
.rsaddon-revealer-3 span:last-child {
	
	animation-delay: -0.8s;
	-webkit-animation-delay: -0.8s;
   
}
@keyframes rsaddon-revealer-3 {
	
	0% {transform: scale(0, 0); opacity:0.5}
	100% {transform: scale(1, 1); opacity:0}
   
}
@-webkit-keyframes rsaddon-revealer-3 {
	
	0% {-webkit-transform: scale(0, 0); opacity:0.5}
	100% {-webkit-transform: scale(1, 1); opacity:0}
   
}

/* *************** */
/* PRELOADER FIVE  */
/* *************** */
.rsaddon-revealer-spinner-4 {
	
	width: 40px;
	height: 40px;
	
}

.rsaddon-revealer-spinner-4 span {
	
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: rsaddon-revealer-4 2.0s infinite ease-in-out;
	animation: rsaddon-revealer-4 2.0s infinite ease-in-out;
	
}

.rsaddon-revealer-spinner-4 span:last-child {
	
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
	
}

@-webkit-keyframes rsaddon-revealer-4 {
	
	0%, 100% {-webkit-transform: scale(0.0)}
	50% {-webkit-transform: scale(1.0)}
	
}

@keyframes rsaddon-revealer-4 {
	
	0%, 100% { 
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
	} 50% { 
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
	
}

/* *************** */
/* PRELOADER SIX   */
/* *************** */
.rsaddon-revealer-spinner-5 {
	
	width: 50px;
	height: 40px;
  
}

.rsaddon-revealer-5 {
	
	width: 100%;
	height: 100%;
	text-align: center;
	font-size: 10px;
	
}

.rsaddon-revealer-5 span {
	
	display: block;
	height: 100%;
	width: 6px;
	display: inline-block;
	-webkit-animation: rsaddon-revealer-5 1.2s infinite ease-in-out;
	animation: rsaddon-revealer-5 1.2s infinite ease-in-out;
}

.rsaddon-revealer-5 span:nth-child(2) {
	
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
  
}

.rsaddon-revealer-5 span:nth-child(3) {
	
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
  
}

.rsaddon-revealer-5 span:nth-child(4) {
	
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
  
}

.rsaddon-revealer-5 span:nth-child(5) {
	
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
  
}

@-webkit-keyframes rsaddon-revealer-5 {
	
	0%, 40%, 100% {-webkit-transform: scaleY(0.4)}  
	20% {-webkit-transform: scaleY(1.0)}
  
}

@keyframes rsaddon-revealer-5 {
	
	0%, 40%, 100% { 
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}  20% { 
		transform: scaleY(1.0);
		-webkit-transform: scaleY(1.0);
	}
  
}

/* ***************** */
/* PRELOADER SEVEN   */
/* ***************** */
.rsaddon-revealer-spinner-10 {
	
	width: 40px;
	height: 40px;
	margin-top: -4px;
  
}

.rsaddon-revealer-10 {
	
	width: 100%;
	height: 100%;
  
}

.rsaddon-revealer-10 span {
	
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: rsaddon-revealer-10 1.8s infinite ease-in-out;
	animation: rsaddon-revealer-10 1.8s infinite ease-in-out;
}

.rsaddon-revealer-10:last-child {
	
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
	
}

@-webkit-keyframes rsaddon-revealer-10 {
	
	25% {-webkit-transform: translateX(30px) rotate(-90deg) scale(0.5)}
	50% {-webkit-transform: translateX(30px) translateY(30px) rotate(-180deg)}
	75% {-webkit-transform: translateX(0px) translateY(30px) rotate(-270deg) scale(0.5)}
	100% {-webkit-transform: rotate(-360deg)}
	
}

@keyframes rsaddon-revealer-10 {
	
	25% { 
		transform: translateX(30px) rotate(-90deg) scale(0.5);
		-webkit-transform: translateX(30px) rotate(-90deg) scale(0.5);
	} 50% { 
		transform: translateX(30px) translateY(30px) rotate(-179deg);
		-webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
	} 50.1% { 
		transform: translateX(30px) translateY(30px) rotate(-180deg);
		-webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
	} 75% { 
		transform: translateX(0px) translateY(30px) rotate(-270deg) scale(0.5);
		-webkit-transform: translateX(0px) translateY(30px) rotate(-270deg) scale(0.5);
	} 100% { 
		transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg);
	}
	
}

/* *************** */
/* PRELOADER EIGHT */
/* *************** */
.rsaddon-revealer-spinner-6 {
	
	width: 40px;
	height: 40px;
	
}

.rsaddon-revealer-6 {
	
	width: 100%;
	height: 100%;
  
}

.rsaddon-revealer-6 span {
	
	display: block;
	width: 33%;
	height: 33%;
	background-color: #333;
	float: left;
	-webkit-animation: rsaddon-revealer-6  1.3s infinite ease-in-out;
	animation: rsaddon-revealer-6  1.3s infinite ease-in-out; 
		  
}
.rsaddon-revealer-6 span:nth-child(1) {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s; 
}
.rsaddon-revealer-6 span:nth-child(2) {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s; 
}
.rsaddon-revealer-6 span:nth-child(3) {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s; 
}
.rsaddon-revealer-6 span:nth-child(4) {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s; 
}
.rsaddon-revealer-6 span:nth-child(5) {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s; 
}
.rsaddon-revealer-6 span:nth-child(6) {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s; 
}
.rsaddon-revealer-6 span:nth-child(7) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s; 
}
.rsaddon-revealer-6 span:nth-child(8) {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s; 
}
.rsaddon-revealer-6 span:nth-child(9) {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s; 
}

@-webkit-keyframes rsaddon-revealer-6 {
	
	0%, 70%, 100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1);
	} 35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1); 
	}
	
}

@keyframes rsaddon-revealer-6 {
	
	0%, 70%, 100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1);
	} 35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1);
	} 
	
}

/* *************** */
/* PRELOADER NINE  */
/* *************** */
.rsaddon-revealer-spinner-7 {
	
	width: 35px;
	height: 35px;
	
}

.rsaddon-revealer-7 {
	
	width: 100%;
	height: 100%;
	-webkit-animation: rsaddon-revealer-7 1s infinite linear;
	animation: rsaddon-revealer-7 1s infinite linear;
  
}

@-webkit-keyframes rsaddon-revealer-7 {
	
	0% {-webkit-transform:rotate(0deg)}
	100% {-webkit-transform:rotate(360deg)}
	
}

@keyframes rsaddon-revealer-7 {
	
	0% {transform:rotate(0deg)}
	100% {transform:rotate(360deg)}
	
}

/* *************** */
/* PRELOADER TEN   */
/* *************** */
.rsaddon-revealer-spinner-9 {
	
	width: 40px;
	height: 40px;
	
}

.rsaddon-revealer-9 {
	
	width: 100%;
	height: 100%;
	animation: rsaddon-revealer-9 1s infinite linear;
  
}

.rsaddon-revealer-9 span {
	
	display: block;
    position: absolute;
	top: 50%;
	left: 50%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
	margin: -8px;
	
}

.rsaddon-revealer-9 span:nth-child(1) {
	
    -webkit-animation: rsaddon-revealer-9-1 2s infinite;
	animation: rsaddon-revealer-9-1 2s infinite;
	
}

.rsaddon-revealer-9 span:nth-child(2) {
	
    -webkit-animation: rsaddon-revealer-9-2 2s infinite;
	animation: rsaddon-revealer-9-2 2s infinite;
	
}

.rsaddon-revealer-9 span:nth-child(3) {
	
    -webkit-animation: rsaddon-revealer-9-3 2s infinite;
	animation: rsaddon-revealer-9-3 2s infinite;
	
}

.rsaddon-revealer-9 span:nth-child(4) {
	
    -webkit-animation: rsaddon-revealer-9-4 2s infinite;
	animation: rsaddon-revealer-9-4 2s infinite;
	
}

@-webkit-keyframes rsaddon-revealer-9-1 {
    0% {-webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0)}
    20% {-webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(80%, 80%, 0)}
    80% {-webkit-transform: rotate3d(0, 0, 1, 360deg) translate3d(80%, 80%, 0)}
    100% {-webkit-transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0)}
}

@-webkit-keyframes rsaddon-revealer-9-2 {
    0% {-webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0)}
    20% {-webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(80%, -80%, 0)}
    80% {-webkit-transform: rotate3d(0, 0, 1, 360deg) translate3d(80%, -80%, 0)}
    100% {-webkit-transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0)}
}

@-webkit-keyframes rsaddon-revealer-9-3 {
    0% {-webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0)}
    20% {-webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(-80%, -80%, 0)}
    80% {-webkit-transform: rotate3d(0, 0, 1, 360deg) translate3d(-80%, -80%, 0)}
    100% {-webkit-transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0)}
}

@-webkit-keyframes rsaddon-revealer-9-4 {
    0% {-webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0)}
    20% {-webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(-80%, 80%, 0)}
    80% {-webkit-transform: rotate3d(0, 0, 1, 360deg) translate3d(-80%, 80%, 0)}
    100% {-webkit-transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0)}
}

@keyframes rsaddon-revealer-9-1 {
    0% {transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0)}
    20% {transform: rotate3d(0, 0, 1, 0deg) translate3d(80%, 80%, 0)}
    80% {transform: rotate3d(0, 0, 1, 360deg) translate3d(80%, 80%, 0)}
    100% {transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0)}
}

@keyframes rsaddon-revealer-9-2 {
    0% {transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0)}
    20% {transform: rotate3d(0, 0, 1, 0deg) translate3d(80%, -80%, 0)}
    80% {transform: rotate3d(0, 0, 1, 360deg) translate3d(80%, -80%, 0)}
    100% {transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0)}
}

@keyframes rsaddon-revealer-9-3 {
    0% {transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0)}
    20% {transform: rotate3d(0, 0, 1, 0deg) translate3d(-80%, -80%, 0)}
    80% {transform: rotate3d(0, 0, 1, 360deg) translate3d(-80%, -80%, 0)}
    100% {transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0)}
}

@keyframes rsaddon-revealer-9-4 {
    0% {transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0)}
    20% {transform: rotate3d(0, 0, 1, 0deg) translate3d(-80%, 80%, 0)}
    80% {transform: rotate3d(0, 0, 1, 360deg) translate3d(-80%, 80%, 0)}
    100% {transform: rotate3d(0, 0, 1, 360deg) translate3d(0, 0, 0)}
}










